<template>
  <div class="login-form login-signin">
    <form
      class="form"
      novalidate="novalidate"
      id="kt_login_signin_form"
      @submit.prevent.stop="onSubmit"
    >
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Bem-vindo ao OptiCert
        </h3>
        <span class="text-muted font-weight-bold font-size-h4"
          >Novo aqui?
          <a
            id="kt_login_signup"
            class="text-primary font-weight-bolder"
            @click="$router.push('/register')"
            >Crie sua Conta!</a
          ></span
        >
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
        <div id="example-input-group-1" label="" label-for="example-input-1">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="text"
            name="email"
            ref="email"
            v-model="form.email"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5"
            >Senha</label
          >
          <a
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            id="kt_login_forgot"
            @click="$router.push('/forgot')"
            >Esqueceu a senha?</a
          >
        </div>
        <div id="example-input-group-2" label="" label-for="example-input-2">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="password"
            name="password"
            ref="password"
            v-model="form.password"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="pb-lg-0 pb-5">
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          Entrar
        </button>
        <!-- <button
          type="button"
          class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
        >
          <span class="svg-icon svg-icon-md">
            <inline-svg src="media/svg/social-icons/google.svg" /> </span
          >Sign in with Google
        </button> -->
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from 'vuex'
import { LOGIN, LOGOUT, ACTIVATE } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  props: ['document'],

  name: 'login',

  data: () => ({
    token: null,
    form: {
      email: null,
      password: null
    }
  }),

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    resetForm() {
      this.form = {
        email: null,
        password: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },

    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      const email = this.$v.form.email.$model
      const password = this.$v.form.password.$model

      this.$store.dispatch(LOGOUT)

      await this.$store
        .dispatch(LOGIN, { email, password })
        .then(() => this.$router.push({ name: 'dashboard' }))
    },

    async activate(token) {
      await localStorage.clear()
      await this.$store
        .dispatch(ACTIVATE, { token: token })
        .then(() => this.$router.push({ name: 'dashboard' }))
    }
  },

  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },

  async mounted() {
    await localStorage.clear()
    if (this.$route.query) {
      if (this.$route.query.token) {
        this.activate(this.$route.query.token)
      }
    }
  }
}
</script>
